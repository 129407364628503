import React from 'react';

const Ser_Hero = () => {
  return (
    <>
      <section className="services__area pt-115 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div
                className="section__title section__title-3 text-center mt-100 mb-0 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h2>Data Science As A Service</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="services__area-2 mt-0 pt-30 pb-140 pl-20 p-relative"
        style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}
      >
        <div className="about__area-2 pt-130 pb-60 pl-20">
          <div className="row text-center">
            <div className="col-xl-4 col-lg-3">
              <h3>Custom AI Solutions</h3>
              <div className="con2">
                <div className="pl-30 pb-50">
                  <img src="assets/img/service/1.jpg" alt="" style={{ width: '80%', height: '100%' }} />
                </div>
              </div>
              <h5 className="ser">We enable organizations to build custom solution & end-to-end AI pipeline</h5>
            </div>

            <div className="col-xl-4 col-lg-3">
              <h3>Develop & Execute AI Strategy</h3>
              <div className="con2">
                <div className="pl-30 pb-50 pt-30">
                  <img src="assets/img/service/2.jpg" alt="" style={{ width: '100%', height: '100%' }} />
                </div>
              </div>
              <h5 className="ser">With deep understanding of AI lifecycle, we help organizations develop and execute AI strategy</h5>
            </div>

            <div className="col-xl-4 col-lg-3">
              <h3>Open Source Tools</h3>
              <div className="con2">
                <div className="">
                  <img src="assets/img/service/3.jpg" alt="" style={{ width: '100%', height: '100%' }} />
                </div>
              </div>
              <h5 className="ser">All of Data Science is open source, we can help leverage the best tools for your organization</h5>
            </div>

            <div className="col-xl-4 col-lg-3">
              <div className="pt-80">
                <h3>Academic Partnership & Consulting</h3>
                <div className="con2">
                  <div className="pt-60" >
                    <img src="assets/img/service/4.jpg" alt="" style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
                <h5 className="ser">We provide faster outcomes & knowledge transfer thought our unique engagement approach</h5>
              </div>
            </div>

            <div className="col-xl-4 col-lg-3">
              <div className="pt-80">
                <h3>Cultural Innovation</h3>
                <div className="con2">
                  <div className="pl-30 pb-50 ">
                    <img src="assets/img/service/5.jpg" alt="" style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
                <h5 className="ser">Enable cultural innovation through our engagement and bring data science to everyone</h5>
              </div>
            </div>

            <div className="col-xl-4 col-lg-3">
              <div className="pt-80">
                <h3>Latest Trends</h3>
                <div className="con2">
                  <div className="">
                    <img src="assets/img/service/6.jpg" alt="" style={{ width: '100%', height: '100%' }} />
                  </div>
                </div>
                <h5 className="ser">Understand latest trends and breakthroughs, partner with academic institutions</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ser_Hero;
