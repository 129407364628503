import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails9area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI launches YouTube Channel</h1>
                           <p className='wee'>March 1, 2023</p>
                           <p className='pb-0'>OneNine AI has just launched a new <a href='https://www.youtube.com/@onenineai' class='link-primary'>YouTube</a> channel dedicated to showcasing our AI-powered solutions and their various features and use cases.</p>
                           <p className='pb-0'>As a cutting-edge AI technology, we understand the importance of keeping our clients informed and up-to-date with the latest developments in the industry. Our new channel is designed to provide you with informative and engaging content that will help you make the most of our solutions. Whether you're new to AI or a seasoned expert, you're sure to find something of interest on our channel.</p>
                           
                           <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail9.jpg" alt="" />
                           </div>
                        </div>

                       

                          
                        

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails9area;