import React, { useRef } from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const Contactform = () => {
   const form = useRef();
   
   const sendEmail = (e) => {
      e.preventDefault();
      const formData = new FormData(form.current);
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      if(formDataObject.user_email==='' || formDataObject.user_name==='' || formDataObject.category==='' || formDataObject.message===''){
         toast.error("All fields are required!");
         return;
      };
      emailjs.sendForm('service_gnn6ykc',
                       'template_t99pp7p',
                        form.current, 
                        'jKVQYQYjxtubD933Q'
                     )
      .then((result) => {
         
         console.log(result.text);
         console.log("message sent");
         e.target.reset();
         toast.success("Email sent succesfully");
         
      }, (error) => {
         console.log(error.text);
      });
  };
   return (
      <>
      <Toaster
            position="top-center"
            reverseOrder={false}
            />
         <section className="contact__area" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
            <div className="container-fluid pb-80" >
               <div className="text-center">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                        <div className="contact__uswrapper d-md-flex justify-content-between">
                           <div className="contact__info mr-50">
                              <h3>Questions? We're here to help.</h3>
                              
                              <ul>
                                 <img src='assets\img\bg\contact.png' width={'200px'} className='mt-10'></img>
                              </ul>
                     
                              <div className="footer__social theme-social pt-30">
                                 <ul>Send email at</ul>
                                 <p><a href="mailto:support@onenine.ai">support@onenine.ai</a></p>
                                 <ul>or Connect with us on</ul>
                                 <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai/" target="_blank" >
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://twitter.com/Onenineai" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 </ul>
                              </div>
                           </div>
                           <div className="contact__form">
                              <form ref={form} onSubmit={sendEmail}>
                                 <input type="text" name="user_name" placeholder="Your Name"/>
                                    <input type="email" name="user_email" placeholder="Your Email"/>
                                    <select name="category" id="reason" style={{ fontSize:'13px',marginBottom: '10px',paddingLeft:'10px',width:'100%',background:'#f5f5f5', padding: '10px',border:'none', borderRadius: '5px' }}>
                                       <option value="">Select a Category</option>
                                       <option value="general-question">General Question</option>
                                       <option value="support-request">Support Request</option>
                                       <option value="ai-academy">AI Academy</option>
                                       <option value="chatbot">ChatBot</option>
                                       <option value="platform-upgrade">Platform Upgrade</option>
                                    </select>
                                       <textarea name="message" placeholder="Your Message"></textarea>
                                       {/* <input type="submit" value="Send" className="z-btn " /> */}
                                       <button type="submit" className="z-btn" >Send Message</button>
                                    </form>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
         
      </>
   );
};

export default Contactform;