import React from 'react';
//import { Link } from 'react-router-dom';

const Cap_ser = ({icon,title}) => {
   return (
      <>
         <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
         <div className="features__item features__item-2 white-bg fix mb-60">
            <div className="features__thumb-2" style={{ backgroundPosition: 'center', backgroundSize: 'cover'}} ></div>
            <div className="features__content-2 pr-55">
               <div className="features__icon features__icon-2">
                  <i > {icon} </i>
               </div>
               <h3>{title}</h3>
               {/* <p>He nicked it Jeffrey zonked cheeky bugger.</p> */}
               
            </div>
         </div>
         </div>
      </>
   );
};

export default Cap_ser;