import React from 'react';
import Capser from '../../../components/Cap_ser/Cap_ser';
//import { TbHandClick } from "react-icons/tb";
import {MdOnlinePrediction, MdBuild, MdDocumentScanner,  MdWeb} from "react-icons/md";
import {AiOutlineBarChart, AiFillCheckCircle, AiOutlineUserAdd} from "react-icons/ai";
import {RiBarChart2Line} from "react-icons/ri";
import {BsDatabaseUp, BsCodeSlash, BsGraphUpArrow} from "react-icons/bs";
import {DiOpensource} from "react-icons/di";

const Capacity = () => {
   return (
      <>
         <section className="services__area pt-115 pb-60">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-70 wow fadeInUp" data-wow-delay=".2s">
                        
                        <h2>Predictive AI Capabilities</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <Capser icon={<MdDocumentScanner />} title="Ask questions to any documents – Word, Excel, PDF and Reports" />
                  <Capser icon={<MdWeb />} title="Analyze, extract, and summarize information from any website" />
                  <Capser icon={<MdOnlinePrediction />} title="Online & Batch Prediction" />
                  <Capser icon={<AiOutlineBarChart />} title="200+ Charts & Visualization" />
                  <Capser icon={<RiBarChart2Line />} title="300+ Machine Learning models" />
                  <Capser icon={<BsDatabaseUp />} title="Clean & Transform Data within seconds" />
                  <Capser icon={<MdBuild />} title="Build & Deploy within minutes" />
                  <Capser icon={<AiFillCheckCircle />} title="Smart Models - Just provide the data and select what you want predict" />
                  <Capser icon={<BsCodeSlash />} title="No Code AI & DL" />
                  <Capser icon={<BsGraphUpArrow />} title="Self-service Visualization without past experience" />
                  <Capser icon={<DiOpensource />} title="Search & Use Open Source Data" />
                  <Capser icon={<AiOutlineUserAdd />} title="Integrate Collaboration Features" />

                 

               </div>
            </div>
         </section>
      </>
   );
};

export default Capacity;