import React from 'react';
import Carousel from 'react-multi-carousel';
// import { CgArrowLongRight } from 'react-icons/cg';
// import { Link } from 'react-router-dom';
const Cards = ({name,desc,bold}) => {
   return (
      <>
         <div className="pl-30 pr-30 ">
            <div className="blog__benefits p-relative fix mb-20 white-bg">
               <div className="" style={{background: `` , backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
               <div className="blog__content">
                    {/* <div className="team__thumb mb-25">
                     <img src={`assets/img/team/team-1.jpg`} alt="team"/>
                     </div> */}
                  <div className="blog__benefits-meta user" >
                     <center>
                     <h3>
                        <span className='review'>{bold}</span>
                     </h3>
                     <div><h5>{desc}</h5></div>
                     </center>
                  </div>
                  
               </div>
            </div>
         </div>
      </>
   );
};

const Reviews = () => {
   const responsive = {
       superLargeDesktop: {
         // the naming can be any, depends on you.
         breakpoint: { max: 4000, min: 3000 },
         items: 5
       },
       desktop: {
         breakpoint: { max: 3000, min: 1024 },
         items: 3
       },
       tablet: {
         breakpoint: { max: 1024, min: 464 },
         items: 2
       },
       mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 1
       }
     };

  return (
     <>
        <section className="blog__area grey-bg-15 pt-115 pb-30">
           
           <div className="container" >
              <div className="row">
                 <div className="col-xl-5">
                    <div className="section__title section__title-3 mb-10 ml-20">
                       
                       <h2>Benefits</h2>
                    </div>
                 </div>
             </div>
           </div>
           <div className='container'>
           <Carousel responsive={responsive} >
            {/* infinite={true} */}
               
               <Cards date="8/7/2022" bold="Cost Reduction" desc=" Reduces the overall cost of AI projects using pre-built templates & automated smart predictive models" />
               <Cards date="8/1/2022" bold="Quick Access to Information" name="- Professor & Researcher" desc="Trained on internal documents provides quick access to public data and relevant company information, reducing time spent searching for documents" />
               <Cards date="8/8/2022" bold="Self-service Knowledge Base" name="- Graduate Student" desc="Ask questions and receive accurate answers, enabling self-sufficiency" />
               
           </Carousel>
           </div>
            
        </section>
     </>
  );
};

export default Reviews;