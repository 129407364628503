import React from 'react';
// import { BiLock } from 'react-icons/bi';
// import { CgArrowLongRight } from 'react-icons/cg';
// import { FaLightbulb, FaRibbon } from 'react-icons/fa';
// import { IoDocumentTextOutline } from 'react-icons/io5';
//import { Link } from 'react-router-dom';


const ImageText = () => {
   return (
      <>
      <section className="services__area pt-115">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mt-100 mb-0 wow fadeInUp" data-wow-delay=".2s">
                        <h2>Data Sense, a Data Intelligence Platform</h2>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="features__area pt-115 pb-0">
            <div className="container">
               <div className="row">
               
                  <div className="col-xl-6 col-lg-6" >
                     <video className='mt-30' width={'95%'} loop={true} autoPlay="autoplay" id="vid" muted>
                        <source src="assets\img\bg\ONAI.mp4" type="video/mp4" />
                        <img className='mt-30' src='assets\img\bg\platformbg.png' width={'95%'}  ></img>
                     </video>
                  </div>
                  
                  <div className=" section__title section__title-3 col-xl-6 col-lg-6 mt-120">
                    <h2>Easy, Fast, One-Click </h2>
                    <h4 className='news'>Data Exploration, Data Visualization, AI Assisted Predictive Modeling & AI Assistant</h4>
                    <a href="https://one.onenine.cloud/register/" target="_blank" rel="noreferrer" className="col-sm-2">
                              <button className='z-btn3 mt-30' style={{width:'180px'}}>Start for Free</button>
                           </a>
                  </div>
                  
               </div>
            </div>
         </section>
      </>
   );
};

export default ImageText;