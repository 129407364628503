import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails7area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI Joins Microsoft Funders Hub, Backed by Tech Giant's Support</h1>
                           <p className='wee'>March 15, 2023</p>
                           <p className='pb-0'>OneNine AI is proud to announce its selection to be part of the esteemed Microsoft Funders Hub. This significant milestone further solidifies OneNine AI's position as a leading player in the AI space, as it receives backing and support from one of the world's technology giants. With this strategic partnership, OneNine AI is poised to leverage Microsoft's expertise and resources to drive innovation and empower individuals with cutting-edge AI.</p>
                           <p className='pb-0'><b>A Powerful Collaboration:</b> The inclusion of OneNine AI in the Microsoft Funders Hub is a testament to the company's dedication to advancing AI and its commitment to excellence. Microsoft, known for its innovative solutions and commitment to fostering technological advancements, recognizes the potential and value of OneNine AI's mission.</p>
                           
                           <p className='pb-0'><b>Harnessing Microsoft's Expertise:</b> As a member of the Microsoft Funders Hub, OneNine AI gains access to a wealth of resources, support, and expertise provided by Microsoft. This collaboration opens doors to a network of industry professionals, mentors, and like-minded innovators.</p>
                           <p className='pb-0'><b>Expanding Opportunities:</b> The partnership with Microsoft introduces new avenues for growth and opportunities for OneNine AI. With access to Microsoft's ecosystem, OneNine AI can explore integrations with existing Microsoft tools and platforms, enabling a seamless experience for learners. This collaboration enables OneNine AI to align its offerings with industry standards and emerging trends, ensuring that learners gain practical skills that are highly relevant in today's AI landscape.</p>
                           <p className='pb-0'>Backed by Microsoft's support and expertise, OneNine AI is well-positioned to accelerate its mission of democratizing AI. This strategic partnership will enable OneNine AI to expand its offerings, foster innovation, and empower individuals with the skills necessary to succeed in the AI-driven world. Together, OneNine AI and Microsoft are poised to lead the way in shaping the future of AI and transforming industries through the power of artificial intelligence.</p>
                           <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail7.jpg" alt="" />
                           </div>
                        </div>

                       

                          
                        

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails7area;