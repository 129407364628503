import React from 'react';
// import SingleService from '../../../components/SingleService_startnow/SingleService';

const Teamhero = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mt-100 mb-0 wow fadeInUp" data-wow-delay=".2s">
                        <h2>Meet Our Team</h2>
                     </div>
                  </div>
               </div>
               </div>
         </section>
               <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="text-center pl-30">
                           <img src="assets/img/ourteam/image-1.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content ">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Ravi Trivedi, Founder & CEO, OneNineAI</h3>
                           </div>
                           <p  >Ravi Trivedi is a founder and advisor of OneNine. Mr. Trivedi is a seasoned data science and analytics executive experienced in driving the strategic initiatives in data science, optimizing mission-critical business processes, developing and operationalizing advanced analytics, and leading full-stack data, data science, and risk teams at Fortune 100 and FinTech. In the past, he has led research teams building innovative products & has worked at leading organizations like Nuveen, TIAA, AT&T, Hess Corporation & TCS. He holds BS in Computer Science and MBA concentrated in Business Analytics from New Jersey Institute of Technology.</p>                              
                           {/* <p className='wee'> Workforce enabled with practical AI skillsets & data driven solutions to their problems</p>   */}
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-2.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section>

         {/* <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="m-img text-center pl-30">
                           <img src="assets/img/ourteam/image-3.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Andre Winebar, Diretor of Global Operations at Google, Board Member</h3>
                           </div>
                           <p>Andre Winebar is an Operations Business Executive with over 20 years of experience growing and scaling businesses. He is most known for his work in the Financial Services and Tech industry successfully leading global teams to achieve maximum potential at several top fortune 500 companies and for advancing corporate cultures as a champion for Diversity, Equity, Inclusion and Belonging. Andre has spent the last eight years of his professional career at Google helping to scale products like Google Payments, Play Store and YouTube where he currently leads global scaled operations. Prior to joining Google, Andre held executive roles at General Electric, American Express and Canon.</p>
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-4.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section> */}

         {/* <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  
                     <div className="col-xl-8 col-lg-3">
                        <div className="about__content pl-20">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Andre Winebar, Diretor of Global Operations at YouTube, Board Member</h3>
                           </div>
                           <p>Andre Winebar is an Operations Business Executive with over 20 years of experience growing and scaling businesses. He is most known for his work in the Financial Services and Tech industry successfully leading global teams to achieve maximum potential at several top fortune 500 companies and for advancing corporate cultures as a champion for Diversity, Equity, Inclusion and Belonging. Andre has spent the last eight years of his professional career at Google helping to scale products like Google Payments, Play Store and YouTube where he currently leads global scaled operations. Prior to joining Google, Andre held executive roles at General Electric, American Express and Canon.</p> 
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-4.png" alt=""/>
                        </div>                    
                        </div>
                     </div>  
                     <div className="col-xl-3 col-lg-3">
                        <div className="m-img pl-30">
                           <img src="assets/img/ourteam/image-3.png" alt=""/>
                        </div>
                    </div>                   
                  </div>
               </div>
         </section> */}
         <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="m-img text-center pl-30">
                           <img src="assets/img/ourteam/image-5.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Patrick Fay, Global C-level Executive, Financial services, Board Member</h3>
                           </div>
                           <p>Patrick is C-level executive with 20+ years’ success penetrating markets and delivering multiple years of budget-breaking revenue results at global financial leaders. Identifies new revenue streams, builds global franchises, enhances efficiencies, and transforms underperforming operations.</p>
                           {/* <p className='wee'> Workforce enabled with practical AI skillsets & data driven solutions to their problems</p>   */}
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-6.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section>

         <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="m-img text-center pl-30">
                           <img src="assets/img/ourteam/image-7.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Kamran Turkoglu,Vice President of Engineering, E-Space, Bridging Earth and space, Board Member</h3>
                           </div>
                           <p>Dr Turkoglu is a seasoned emerging technologies/markets executive leader, with diverse advanced/innovative technology leadership and architecture background. Dr. Turkoglu possesses experience with concentration on emerging technologies, partnerships, markets, innovation, and business development, and holds past collaboration experience with many industry and academia partners including but not limited to NASA Ames Research Center, NIO-Autonomous Driving division, National Science Foundation, US Air Force Directorate and more. He has been actively participating as a Board Member in different organizations and has unique interest in data analytics, AI, innovative technologies and emerging market applications of AI.</p>
                           {/* <p className='wee'> Workforce enabled with practical AI skillsets & data driven solutions to their problems</p>   */}
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-8.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section>

         <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="m-img text-center pl-30">
                           <img src="assets/img/ourteam/image-9.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Michael Ciancio, Vice President of Product & Solutions Marketing, IntelePeer, Board Member</h3>
                           </div>
                           <p>Michael Ciancio is Product Marketing and Go-To-Market strategy executive who has successfully driven strategic growth and product visibility for market leading organizations. He is a leader who is highly competitive, passionate and persuasive in achieving team results. His technical skills focus in solutions strategy, product development and brand management.</p>
                           {/* <p className='wee'> Workforce enabled with practical AI skillsets & data driven solutions to their problems</p>   */}
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-10.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section>
         <section className="services__area-2 mt-0 pt-30 pb-140 p-relative" style={{ background: ``, backgroundPosition: 'top', backgroundSize: 'cover' }}>      
               <div className="about__area-2 pt-130 pb-60 pl-20">
                  <div className="row">
                  <div className="col-xl-3 col-lg-3">
                        <div className="m-img text-center pl-30">
                           <img src="assets/img/ourteam/image-11.png" alt=""/>
                        </div>
                    </div>
                     <div className="col-xl-9 col-lg-3">
                        <div className="about__content">
                           <div className="section__title section__title-sh mb-25 ">
                              <h3 >Paul served in leadership roles at FleetCor,  Jack Henry , Fiserv, S1 and Unisys, Board Member</h3>
                           </div>
                           <p>Paul is an advisor who combines strategic thinking and detailed execution to drive incremental revenue/profit growth. He is experienced with high-growth, profit-oriented companies with a concentration in General Management, Sales, and Marketing.   Today, Paul works with growth-oriented companies, helping them develop and implement approaches to drive sustainable revenue growth. As an Advisor, Board Member and/or Investor he works with the executive leadership teams of small and mid-sized organizations in optimizing their Go-to-Market approaches.   Strong experience in the Fintech, SAAS and the payments industry.</p>
                           {/* <p className='wee'> Workforce enabled with practical AI skillsets & data driven solutions to their problems</p>   */}
                           <div className="m-img">
                           <img src="assets/img/ourteam/image-12.png" alt=""/>
                        </div>                      
                        </div>
                     </div>                     
                  </div>
               </div>
         </section>
         
               
            
      </>
   );
};

export default Teamhero;