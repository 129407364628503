import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
// import About from "./pages/About/About";
// import BlogDetails from "./pages/BlogDetails/BlogDetails";
// import Blogs from "./pages/Blogs/Blogs";
// import Contact from "./pages/Contact/Contact";
// import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
// import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
// import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
// import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
// import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
// import HomeTwo from "./pages/HomeTwo/HomeTwo";
// import Portfolio from "./pages/Portfolio/Portfolio";
// import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
// import Services from "./pages/Services/Services";
// import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
// import Team from "./pages/Team/Team";
// import TeamDetails from "./pages/TeamDetails/TeamDetails";

import Platform from "./pages/Platform/Platform";
import Solutions from "./pages/Solutions/Solutions";
import Startnow from "./pages/Startnow/Startnow";
import AIAcademy from './pages/AIAcademy/AIAcademy';
import Ourteam from './pages/Ourteam/Ourteam';
import Partner from './pages/Partner/Partner';
import Service from './pages/Service/Service';
import News from './pages/News/News';
import Usecases from './pages/Usecases/Usecases';
import Bookdemo from './pages/Bookdemo/Bookdemo';
import Newsdetails1 from "./pages/Newsdetails1/Newsdetails1";
import Newsdetails2 from './pages/Newsdetails2/Newsdetails2';
import Newsdetails3 from './pages/Newsdetails3/Newsdetails3';
import Newsdetails4 from './pages/Newsdetails4/Newsdetails4';
import Newsdetails5 from './pages/Newsdetails5/Newsdetails5';
import Newsdetails6 from './pages/Newsdetails6/Newsdetails6';
import Newsdetails7 from './pages/Newsdetails7/Newsdetails7';
import Newsdetails8 from './pages/Newsdetails8/Newsdetails8';
import Newsdetails9 from './pages/Newsdetails9/Newsdetails9';
import Newsdetails10 from './pages/Newsdetails10/Newsdetails10';

import Privacy from "./pages/Privacy/Privacy";
import Contactus from "./pages/Contactus/Contactus";
import Chatbot from "./pages/Chatbot/Chatbot";
import Newsdetails11 from "./pages/Newsdetails11/Newsdetails11";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


function App() {
  return (
    <>
     <div className="App">
            <TawkMessengerReact
                propertyId="641f2a174247f20fefe7f0d0"
                widgetId="1gscrorem"/>
        </div>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<HomeThree/>} />
            
            <Route path="/platform" element={<Platform/>} />
            <Route path="/solutions" element={<Solutions/>} />
            <Route path="/pricing" element={<Startnow/>} />
            <Route path="/ai-academy" element={<AIAcademy/>} />
            <Route path="/our-team" element={<Ourteam/>} />
            <Route path="/partners" element={<Partner/>} />
            <Route path="/services" element={<Service/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/usecases" element={<Usecases/>} />
            <Route path="/book-demo" element={<Bookdemo/>} />
            {/* <Route path="/release-notes" element={<Releasenotes/>} /> */}
            <Route path="/newsdetails1" element={<Newsdetails1/>} />
            <Route path="/newsdetails2" element={<Newsdetails2/>} />
            <Route path="/newsdetails3" element={<Newsdetails3/>} />
            <Route path="/newsdetails4" element={<Newsdetails4/>} /> 
            <Route path="/newsdetails5" element={<Newsdetails5/>} /> 
            <Route path="/newsdetails6" element={<Newsdetails6/>} /> 
            <Route path="/newsdetails7" element={<Newsdetails7/>} /> 
            <Route path="/newsdetails8" element={<Newsdetails8/>} /> 
            <Route path="/newsdetails9" element={<Newsdetails9/>} /> 
            <Route path="/newsdetails10" element={<Newsdetails10/>} />
            <Route path="/newsdetails11" element={<Newsdetails11/>} />
            <Route path="/contact" element={<Contactus/>} />
            <Route path="/chatbot" element={<Chatbot/>} />

            <Route path="/privacy" element={<Privacy/>} />
           {/* <Route path="*" element={<HomeThree/>} /> */}
            



            


            



            {/* <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blogDetails" element={<BlogDetails/>} />
            <Route path="/contact" element={<Contact/>} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
