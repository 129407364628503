import React from 'react';
// import { BiLock } from 'react-icons/bi';
// import { CgArrowLongRight } from 'react-icons/cg';
// import { FaLightbulb, FaRibbon } from 'react-icons/fa';
// import { IoDocumentTextOutline } from 'react-icons/io5';
//import { Link } from 'react-router-dom';
import { TbHandClick, TbLanguage } from "react-icons/tb";
import { FaHandshake } from "react-icons/fa";
import { MdDocumentScanner, MdSecurity } from 'react-icons/md';
const FeatureCards2 = ({ icon, title }) => {
    return (
      <>
        <div className="features__item features__item-2 white-bg fix mb-30">
          <div
            className="features__thumb-2"
            style={{ backgroundPosition: 'center', backgroundSize: 'cover' }}
          ></div>
          <div className="features__content-2">
            <div
              className="features__icon features__icon-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <i>{icon}</i>
            </div>
            <h3>{title}</h3>
            {/* <p>He nicked it Jeffrey zonked cheeky bugger.</p> */}
            <div className="features__btn-2"></div>
          </div>
        </div>
      </>
    );
  };

const Features2 = () => {
   return (
      <>
         <section className="features__area pt-115 pb-0">
            <div className="container">
               <div className="row">
               
                  <div className="col-xl-6 col-lg-6" >
                  <div className="hero__content mb-30 ml-20">
                           <span className='ai'>Key Features</span>
                           {/* <h2>Enrich <br/> your design</h2> */}
                            </div>
                     <video className='mt-30' width={'95%'} height={'500px'} loop={true} autoPlay="autoplay" id="vid" muted>
                        <source src="assets\img\bg\Aivagif.webm" type="video/webm" style={{boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px'}} />
                        <img className='mt-30' src='assets\img\bg\chatbot.png' width={'95%'} height={'500px'}></img>
                     </video>
                        {/* <img className='mt-30' src='assets\img\bg\platformbg.png' width={'95%'}  ></img> */}
                        <div className=''>
                           <a href="assets\img\OneNine_AI_Assistant_for_Everyone.pdf" target="_blank" rel="noreferrer" className='ml-200'>
                             <button className='z-btn3'>Learn more</button>
                           </a></div>
                  </div>
                  
                  <div className="col-xl-6 col-lg-6 mt-50">
                     <div className="features__content-right">
                        <div className="row justify-content-center">
                        
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                           <FeatureCards2 icon={<TbHandClick />} title="Advanced AI bot can be built by anyone using OneNine's No Code platform" />
                           <FeatureCards2 icon={<FaHandshake />} title="Seamlessly integrate with over 200+ enterprise systems including Sharepoint, Confluence, and various databases" />
                              

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                           <FeatureCards2 icon={<MdDocumentScanner />} title="Interacts with your documents – Word, Excel, PDF, Text, PowerPoint and Reports" />
                           <FeatureCards2 icon={<TbLanguage />} title="Break down language barriers using unparalleled translation capabilities across all languages" />

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Features2;