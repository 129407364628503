import React from 'react';



const Hero_sec = () => {
   return (
      <>
         <section className="services__area-2 mt-80 pb-140 p-relative" style={{ background: `url(assets/img/bg/bg-new.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
            
               <div className="pl-20">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 mt-60">
                        <div className="m-img">
                           <img src="assets/img/about/academy.png" alt=""/>
                        </div>
                    </div>
                    <div className="testing">
                        <div className="about__content pl-10 mb-40 ">
                           <div className="section__title section__title-3 mb-20 mt-60">
                              <h3 >We are bringing AI and data literacy to 10 million people worldwide through a partnership with Salesforce.</h3>
                           </div>
                           <h4 className='news'>Innovative Learning approach focuses on solving real world challenges and reducing entry barriers for businesses to start using AI and Machine Learning. We have partnered with leading research institutions around that world to bring our state-of-the-art platform to classroom and academic research.</h4>
                           
                           <a href="https://trailhead.salesforce.com/content/learn/modules/data-fundamentals-for-ai" target="_blank" rel="noreferrer" className="col-sm-2">
                              <button className='z-btn mt-30 mr-10' style={{width:'280px'}}>Take Our Free AI Course</button>
                           </a>
                           <a href="https://buy.stripe.com/28o9Co7X26pn4Y8eUX" target="_blank" rel="noreferrer" className="col-sm-2">
                              <button className='z-btn mt-30' style={{width:'340px'}}>Signup for 6 weeks training course</button>
                           </a>
                        </div>
                     </div>

                     
                  </div>
               </div>
            
         </section>
      </>
   );
};

export default Hero_sec;