import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails11area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>Embrace the Future: OneNine AI and Salesforce Trailhead Launch Data Fundamentals for AI Course</h1>
                           <p className='wee'>September 9, 2023</p>
                           <p className='pb-0'>Data fundamentals are super important in the age of Generative AI. We are thrilled to announce our partnership with Salesforce Trailhead and the launch of the much-awaited Data Fundamentals for AI course. In this training module, we aim to equip individuals and businesses with the essential knowledge of data and ethics, preparing them to embrace the AI innovations that are shaping our future.</p>
                           
                           <p className='pb-0'>As AI continues to shape our future, it is essential to be equipped with the right knowledge and skills to navigate this evolving landscape. We invite you to embark on this exciting learning journey with us and be at the forefront of the AI revolution.</p>
                           <p className='pb-0'>Click <a className='link-primary' href='https://trailhead.salesforce.com/content/learn/modules/data-fundamentals-for-ai?trail_id=get-started-with-ai-data' target="_blank">here</a> to access the course: Data Fundamentals for AI</p>
                        </div>

                        <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail11.jpg" alt="" />
                        </div>
                        <div className="blog__text mb-40">
                           <p className='pb-0'><b>The Importance of Data : </b>Data is the lifeblood of AI. It fuels the algorithms that power intelligent systems, enabling them to learn, adapt, and make informed decisions. As AI continues to revolutionize industries, having a solid understanding of data fundamentals becomes paramount. Our course, developed in collaboration with Salesforce Trailhead, provides a comprehensive overview of the key concepts and principles that underpin the data-driven AI landscape.</p>
                           <p className='pb-0'><b>Ethics in AI : </b>With great power comes great responsibility. As AI advances, it is crucial to address the ethical implications that arise. Our course goes beyond the technical aspects of AI and delves into the ethical considerations surrounding its use. We believe in promoting responsible AI practices and empowering individuals to navigate the ethical challenges that AI presents.</p>
                           <p className='pb-0'><b>Course Highlights : </b>The Data Fundamentals for AI course covers a wide range of topics, including data collection, storage, processing, and analysis. Participants will gain insights into data quality, privacy, security, and governance. The course also explores the ethical considerations of AI, such as bias, fairness, transparency, and accountability.</p>
                           <p className='pb-0'><a className='link-primary' href='https://trailhead.salesforce.com/content/learn/trails/get-started-with-ai-data' target="_blank">https://trailhead.salesforce.com/content/learn/trails/get-started-with-ai-data</a></p>
                           <p className='pb-0'>Stay tuned for updates and be ready to embrace the future with OneNine AI and Salesforce Trailhead. Together, let's unlock the potential of AI while upholding data integrity, ethics, and responsible practices.</p>
                        </div>

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails11area;