import React from 'react';
// import SingleService from '../../../components/SingleService/SingleService';


import plat_Hero1 from './sub_hero-1.json';
import plat_Hero2 from './sub_hero-2.json';

import Lottie from 'lottie-react';



const Steps = () => {
   return (
      <>
         <section className="services__area pt-35 pb-0">
            
            <section className="services__area-2 mt-50 pt-70 pb-0 p-relative" style={{ background: `url(assets/img/bg/bg-new.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
            
               <div className="about__area-2 pt-130 pl-20">
                  <div className="row">
                  <Lottie className="col-xl-3 col-lg-3" speed={null} loop={true} animationData ={plat_Hero1} />
                  {/* <div className=""> */}
                    <div className="testing">
                     {/* <div className="d-flex align-middle"> */}
                        <div className="about__content pl-140">
                           <div className="section__title section__title-3 mb-0 mt-10 ">
                              <h3 >Create & Train secure enterprise bot in few clicks using No Code technology</h3>
                           </div>
                        </div>
                        {/* </div> */}
                     </div>                     
                  </div>
               </div>
         </section>
         {/* <div className='text-center' style={{ background: `url(assets/img/bg/4.png)`, backgroundPosition: 'center', backgroundSize: 'cover' }} ><img src='assets\img\bg\arrow.png' width={'100px'} ></img></div> */}
         <section className="services__area-3 pb-10" style={{ background: `url(assets/img/bg/1.png`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
            
               <div className="about__area-2 pt-0 pl-20">
                  <div className="row">
                  <div className="testing">
                        <div className="about__content pl-10 ">
                           <div className="section__title section__title-3 mb-15 mt-0">
                              <h3 >Setup regular crawls on various data sources, such as company SharePoint, team confluence and 200+ other connectors</h3>
                           </div>
                        </div>
                     </div>  
                   
                   <Lottie className="col-xl-4 col-lg-4" speed={null} loop={true} animationData ={plat_Hero2} />
                  
                  
                                      
                  </div>
               </div>  
         </section>
         </section>
      </>
   );
};

export default Steps;