import React, { useState, useEffect } from 'react';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeHeader from './HomeThreeHeader/HomeThreeHeader';
import HomeThreeHeroSection from './HomeThreeHeroSection/HomeThreeHeroSection';
import HomeThreeServices from './HomeThreeServices/HomeThreeServices';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import Counter from './Counter/Counter';
import Textanimation from './Textanimation/Textanimation';
import Capacity from './Capacity/Capacity';
import HomeTwoFeatures from './HomeTwoFeatures/HomeTwoFeatures';
import ImageBoot from './ImageBoot/ImageBoot';
import Backedby from '../Partner/Backedby/Backedby';
import Carousel from './Carousel/Carousle';
import HomeThreeFooter from './HomeThreeFooter/HomeThreeFooter';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useRef } from 'react';
import { MdCancel } from 'react-icons/md';
import toast, { Toaster } from 'react-hot-toast';
import Features from '../Chatbot/Features/Features';

const HomeThree = () => {
   const [isLoaded, setIsLoaded] = useState(false);

   useEffect(() => {
     const timer = setTimeout(() => {
       setIsLoaded(true);
     }, 2000);
     return () => clearTimeout(timer);
   }, []);
   const form = useRef();
   const handlePopUpClose=()=>{
      setIsLoaded(false);
      // 
   }
   const handleSubmit = async (e) => {
      e.preventDefault(); 
      const formData = new FormData(form.current);
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      if(formDataObject.user_email==='' || formDataObject.first_name==='' || formDataObject.last_name===''){
         toast.error("All fields are required!");
         return;
      };
      const apiUrl = 'https://chat.onenine.ai/api/mainsitedata';
      handlePopUpClose();
      toast.success("Thanks for signing up for free trial!");
    
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formDataObject),
        });
    
        if (response.ok) {

          console.log('Form data sent successfully');
        } else {

          console.error('Error sending form data');
        }
      } catch (error) {

        console.error('Error:', error);
      }
   };
   
   
   
   return (
      <>
      {isLoaded &&(
         
         <Popup
         open={isLoaded}
         contentStyle={{ width: '80%',minheight:'80%',borderRadius:'30px'}}
         modal nested>
         {
            <div>
               <div >
                  <button style={{position:'absolute',right:'20px',top:'20px',background:'white',fontSize:'24px'}} onClick={handlePopUpClose}><MdCancel/></button>
               <div className="text-center">
                  <div className="col-xl-12">
                     <div className="container">
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                        <div className="justify-content-between mt-40 mb-40 row">
                           <div className="contact__info mr-50 col-md">
                              <h3>Sign up for a free 14-day trial!</h3>
                              
                              <ul className='d-md-block d-none'>
                                 <img src='assets\img\bg\contact.png' width={'180px'} className='mt-10' alt=''></img>
                              </ul>
                     
                              <div className="footer__social theme-social">

                              <ul>We are excited to introduce you to our powerful AI platforms, AIVA and DataSense</ul>
                              <ul>which are reshaping the future of work by seamlessly integrating human and AI</ul> 


                              <ul></ul>

                              <b><ul className='d-md-block d-none'>Trial Includes: AI Virtual Assistant, One-Click Data Exploration, 200+ Visualizations, AI Assisted Predictive Modeling & Real Time APIs</ul></b>

                                 {/* <p><a href="mailto:support@onenine.ai">support@onenine.ai</a></p>
                                 <ul>or Connect with us on</ul> */}
                                 {/* <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai/" target="_blank" >
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://twitter.com/Onenineai" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 </ul> */}
                              </div>
                           </div>
                           <div className="contact__form2 col-md">
                           <form ref={form} onSubmit={handleSubmit}>
                                 <input type="first" name="first_name" placeholder="First Name"/>
                                 <input type="last" name="last_name" placeholder="Last Name"/>
                                 <input type="email" name="user_email" placeholder="Your Email"/>
                                 {/* <input type="phone" name="phone" placeholder="Phone Number"/> */}
                                 <button type="submit" className="z-btn" >Register</button>
                              </form>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
     </Popup>
      )}
      <PageHelmet pageTitle="OneNine is bringing the power of AI to everyone" />
      <HomeThreeHeader />
      <HomeThreeHeroSection />
      <HomeThreeServices />
      <Features title="Generative AI Capabilities"/>
      <Carousel />
      <HomeHeroSlider />
      <Backedby />
      <Counter />
      <Textanimation />
      <Capacity />
      <HomeTwoFeatures />
      <ImageBoot />
      <HomeThreeFooter />
      <Toaster
      position="bottom-center"
      reverseOrder={false}
      />
      
    </>
  );
};

export default HomeThree;
