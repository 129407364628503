import React, { useRef } from 'react';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeHeader from '../HomeThree/HomeThreeHeader/HomeThreeHeader';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import ChatbotHero from './ChatbotHero/ChatbotHero';
import Features from './Features/Features';
import Steps from './Steps/Steps';
import BenefitsCard from './BenefitsCard/BenefitsCard';
import Features2 from './Features/Features2';
import Trialform from './ChatbotHero/tform';
import ModelCards from './AvailableModels/AvailableModels';


const Chatbot = () => {
   const divToScroll = useRef(null);

  // Function to scroll to the div
  const scrollToDiv = () => {
    divToScroll.current.scrollIntoView({ behavior: 'smooth' });
  };
   return (
      <>
         <PageHelmet pageTitle="OneNine AIVA Platform" />
         <HomeThreeHeader/>
         <ChatbotHero scroll={scrollToDiv}/>
         <ModelCards title="Available Models"/>
         <Features title="Available Agents"/>
         <Features2/>
         <Steps/>
         <div ref={divToScroll}></div>
         <Trialform />
         <BenefitsCard/>
{/* 
         <Hero/>
         <PlatformFeatures/> */}
         <HomeThreeFooter/>

      </>
   );
};

export default Chatbot;