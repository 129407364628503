import React from 'react';
import Calendly from '../../../components/Calendly/Calendly';




// import HomeHeroSlider from '../HomeThree/HomeHeroArea/HomeHeroSlider';




const Cal = () => {
   return (
      <>
   
         <Calendly/>
         <div className="mt-50 mb-100">

         </div>

      </>
   );
};

export default Cal;