import React, { useRef } from 'react';
// import { FaTwitter, FaLinkedin } from 'react-icons/fa';
// import emailjs from '@emailjs/browser';
// import { useState } from 'react';
import toast from 'react-hot-toast';

const Trialform = () => {
   const form = useRef();


   const handleSubmit = async (e) => {
      e.preventDefault(); 
      const formData = new FormData(form.current);
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      if(formDataObject.user_email==='' || formDataObject.first_name==='' || formDataObject.last_name===''){
         toast.error("All fields are required!");
         return;
      };
      const apiUrl = 'https://chat.onenine.ai/api/mainsitedata';
      // handlePopUpClose();
      toast.success("Thanks for signing up for free trial!");
    
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formDataObject),
        });
    
        if (response.ok) {

          console.log('Form data sent successfully');
        } else {

          console.error('Error sending form data');
        }
      } catch (error) {

        console.error('Error:', error);
      }
  };
   return (
      <div id='tform'>
         <section className="contact__area" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
            <div className="container-fluid pb-80" >
               <div className="text-center">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                        <div className="contact__uswrapper d-md-flex justify-content-between">
                           <div className="contact__info mr-50">
                              <h3>Sign up for a free 14-day trial!</h3>
                              
                              <ul>
                                 <img src='assets\img\bg\contact.png' width={'200px'} className='mt-10'></img>
                              </ul>
                     
                              <div className="footer__social theme-social pt-30">
                                 <ul>Try the OneNine AI enterprise bot for yourself, and explore features</ul>
                                 <ul>such as document, upload, translate, URL extraction, and more! </ul>
                                 <ul>A new bot instance can be easily created by anyone and trained </ul>
                                 <ul>on the data provided to get a secure, customized bot!</ul>
                                 
                                 {/* <p><a href="mailto:support@onenine.ai">support@onenine.ai</a></p>
                                 <ul>or Connect with us on</ul> */}
                                 {/* <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai/" target="_blank" >
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://twitter.com/Onenineai" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 </ul> */}
                              </div>
                           </div>
                           <div className="contact__form2">
                           <form ref={form} onSubmit={handleSubmit}>
                                 <input type="first" name="first_name" placeholder="First Name"/>
                                 <input type="last" name="last_name" placeholder="Last Name"/>
                                 <input type="email" name="user_email" placeholder="Your Email"/>
                                 {/* <input type="phone" name="phone" placeholder="Phone Number"/> */}
                                 <button type="submit" className="z-btn" >Register</button>
                              </form>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
         
      </div>
   );
};

export default Trialform;