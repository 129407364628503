import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import '../Features/AgentsCard.css';

const AgentCard = ({ imageSrc, desc, bold }) => {
  return (
    <div className='model-card'>
      <div className="card-link">
        <div className="article-wrapper">
          <div className='model-img'>
            <img src={imageSrc} alt="" />
          </div>
          <div className="article-body2">
            <h3>{bold}</h3>
            {/* <p>{desc}</p> */}
          </div>
        </div>
      </div >
    </div>
  );
};

const ModelCards = ({title}) => {
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
      }
    };

    const agentsData = [
      {
        imageSrc: 'assets/img/OpenAI.png',
        bold: 'OpenAI',
      },
      {
        imageSrc: 'assets/img/Mistral.png',
        bold: 'Mistral',
      },
      {
        imageSrc: 'assets/img/MPT7.png',
        bold: 'MPT7',
      },
    ];
  
  return (
    <>
      <section className="blog__area grey-bg-20 pt-115 pb-20" style={{ background: `url(assets/img/bg/bg-new.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
        <div className="container">
          <div className="row">
          <div className="section__title section__title-3 text-center mb-50 wow fadeInUp" data-wow-delay=".2s">
                <h3>{title}</h3>
              </div>
          </div>
        </div>
        <div className="container">
          <Carousel responsive={responsive} infinite={true} showDots={false} arrows={false} swipeable={false}>
            {agentsData.map((agent, index) => (
              <AgentCard key={index} {...agent} />
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};

export default ModelCards;
