import React, { useState } from 'react';
import SinglePrice from '../../../components/SinglePrice/SinglePrice';
import SinglePrice2 from '../../../components/SinglePrice/SinglePrice2';
import { MdDocumentScanner, MdWeb } from 'react-icons/md';
import SinglePrice3 from '../../../components/SinglePrice/SinglePrice3';

const Pricing = () => {
  const [selectedProduct, setSelectedProduct] = useState('AIVA');

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  return (
    <>
      <section className="price__area pt-115 pb-75" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section__title section__title-3 mb-85 text-center">
                <span>Our Prices</span>
                <h2>Choose From The Following Products</h2>
                <div className="tab-pane fade show active d-flex justify-content-center align-items-center mt-40">
                  <div className="row">
                    <div className="col-6 col-md-6">
                      <button
                        className={`features__item features__item-2 features__item-3 white-bg reverse-hover mr-10 fix ${
                          selectedProduct === 'DIA' ? 'active' : ''
                        }`}
                        style={{ height: '100%' }}
                        onClick={() => handleProductChange('DIA')}
                      >
                        <div
                          className="features__thumb-2"
                          style={{
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }}
                        ></div>
                        <div className="features__content-2">
                          <div className="features__icon features__icon-2">
                            <i>
                              <MdDocumentScanner />
                            </i>
                          </div>
                          <h3>Data Intelligence Platform (DIA)</h3>
                        </div>
                      </button>
                    </div>
                    <div className="col-6 col-md-6">
                      <button
                        className={`features__item features__item-2 features__item-3 white-bg ml-10 fix ${
                          selectedProduct === 'AIVA' ? 'active' : ''
                        }`}
                        style={{ height: '100%' }}
                        onClick={() => handleProductChange('AIVA')}
                      >
                        <div
                          className="features__thumb-2"
                          style={{
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }}
                        ></div>
                        <div className="features__content-2">
                          <div className="features__icon features__icon-2">
                            <i>
                              <MdWeb />
                            </i>
                          </div>
                          <h3>AI Virtual Assistant (AIVA)</h3>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="price__tab-content">
            <div className="tab-content" id="price-tab-content">
              <div
                className={`tab-pane fade show active`}
                id="yearly"
                role="tabpanel"
                aria-labelledby="yearly-tab"
              >
                <div className="row d-flex justify-content-center">
                  {selectedProduct === 'DIA' && (
                    <>
                      <SinglePrice
                        title="OneNine AI Standard"
                        price="70"
                        one='One Click Data Analysis'
                        two='200+ Data Visualizations & Charts'
                        three='Open Source Data Search'
                        four='Learn AI, ML and Deep Learning'
                        five='Create & Host Dashboard'
                        six='Up to 5GB Storage & Processing'
                        seven='Up to 20 Projects'
                        link='https://buy.stripe.com/6oEaGsdhm3db62cdQQ'
                      />
                      <SinglePrice2
                        title="OneNine AI Pro"
                        price="100"
                        active="active"
                        one='This includes:'
                        two='All Standard Features'
                        three='Predictive Modeling Building'
                        four='Deep Learning Model Building'
                        five='Pick & Choose AI Model'
                        six='Up to 10GB Storage & Processing'
                        seven='Up to 50 Projects'
                      />
                      <SinglePrice
                        title="OneNine AI Pro +"
                        price="120"
                        one='This includes:'
                        two='All Pro Features'
                        three='Predictive Modeling Hosting'
                        four='Real Time APIs'
                        five='Batch & Adhoc Predictions'
                        six='Unlimited Storage & Processing'
                        seven='Up to 200 Projects'
                        link='https://buy.stripe.com/cN2dSE916aFD76gbIK'
                      />
                    </>
                  )}
                  {selectedProduct === 'AIVA' && (
                    <>
                      <SinglePrice3
                        title="AIVA User"
                        price="20"
                        one="Use AI Agent to automate day to day tasks"
                        two="Interacts with your documents – Word, Excel, PDF, Text, PowerPoint and Reports"
                        three="Break down language barriers using unparalleled translation capabilities across all languages."
                        four="Interact with specialized agents such as Online Research, Code Assistant , Medical Assistant and Legal Agent."
                        
                        link="https://buy.stripe.com/3cs01O3GMfZXcqA5kq"
                      />
                      <SinglePrice3
                        title="AIVA Creator"
                        active="active"
                        price="100"
                        one="Create and Train secure Enterprise AI Agents"
                        two="Choose from securely hosted Large Language models"
                        three="Seamlessly integrate with over 200+ enterprise systems including Sharepoint, Confluence, and various databases."
                        four="Manage AI Agents efficiently and securely share with other users across your entire company network."
                        
                        link='https://buy.stripe.com/28o5m8a5a3db1LW6ov'
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
