import React from 'react';
// import { CgArrowLongLeft, CgArrowLongRight } from 'react-icons/cg';
// import { FiPlay } from 'react-icons/fi';
// import { IoEllipsisHorizontal } from 'react-icons/io5';
// import { Link } from 'react-router-dom';
import Newsitem from '../../../components/Newsitem/Newsitem';
import VideoPopup from '../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../hooks/useGlobalContext';
// import BlogLeftSideBar from './BlogLeftSideBar';

const BlogsArea = () => {
   const { setIsVideoOpen} = useGlobalContext();
   return (
      <>
         <VideoPopup videoId="yJg-Y5byMMw"/>

         <section className="blog__area pt-70 pb-120">
            <div className="container">
               <div className="row">

               <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails11" date="September 9, 2023" image="13" name="#OneNineAI #SalesforceTrailhead #DataFundamentalsforAI #AIforGood" title="Embrace the Future: OneNine AI and Salesforce Trailhead Launch Data Fundamentals for AI Course" desc="Data fundamentals are super important in the age of Generative AI. We are thrilled to announce our partnership with Salesforce Trailhead and the launch of the much" />
                        </div>
                  </div>
               <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails10" date="July 4, 2023" image="12" name="#AI #AIinEducation #NoCode #Onenineai" title="OneNine AI named key player for Global Artificial Intelligence (AI) in Education Market" desc="OneNine AI is named as a top players in AI Education market by Information Research Insights. The Global Artificial Intelligence (AI) in Education Market is expected to witness an" />
                        </div>
                  </div>
                  
                  

               <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails5" date="April 20, 2023" image="7" name="#AI #Partnership #Nvidia #NoCode #Onenineai" title="OneNine AI partners with NVIDIA accelerate AI development using OneNine's No Code Platform" desc="We're excited to announce our partnership with Nvidia to bring you the most powerful and efficient no code AI platform on the market!" />
                        </div>
                  </div>
                  
                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails7" date="March 15, 2023" image="9" name="#Microsoft #Startup #FundersHub #Onenineai" title="OneNine AI Joins Microsoft Funders Hub, Backed by Tech Giant's Support" desc="OneNine AI is proud to announce its selection to be part of the esteemed Microsoft Funders Hub. This significant milestone further solidifies OneNine AI's position as a leading player in the AI space" />
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails9" date="March 1, 2023" image="11" name="#Onenineai #Youtube #NoCode #AI" title="OneNine AI launches YouTube Channel" desc="OneNine AI has just launched a new YouTube channel dedicated to showcasing our AI-powered solutions and their various features and use cases" />
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails8" date="February 1, 2023" image="10" name="#AI #NJIT #Students #Onenine #NoCode" title="OneNine AI Partners with NJIT to Forge the Workforce of the Future with AI Skills" desc="OneNine AI, a pioneering AI platform, recently participated in the highly anticipated Capstone program organized by the esteemed New Jersey Institute of Technology (NJIT). With an impressive turnout of over 300+ students" />
                        </div>
                  </div>

                  

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails6" date="November 1, 2022" image="8" name="#SWaMfest #NoCode #NoCode #Onenineai" title="OneNine AI Shines at SWaMfest, Presenting a Breakthrough No-Code AI Platform" desc="OneNine AI recently made waves at the highly acclaimed SWaMfest event. With a captivating presentation, OneNine AI showcased its cutting-edge no-code AI platform" />
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                        <Newsitem to="newsdetails1" date="December 14, 2021" image="2" name="#AiAcademy #AI #Learning #Onenineai" title="Launching OneNineAI Academy" desc="We are very excited to launch OneNine AI Academy, introducing an innovative way of learning AI.  Innovative Learning approach focuses on solving real world challenges and reducing entry barriers for students & individuals" />
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                     <Newsitem to="newsdetails2" date="August 15, 2021" image="4" name="#AI #Partnership #NJIT #NoCode #Onenineai" title="OneNineAI partners with a top public research university" desc="We are excited to announce our partnership with NJIT, a top public research university conducting world-class technological research. This partnership will help us bring best of academic research to our platform while"/>
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                     <Newsitem to="newsdetails3" date="August 15, 2021" image="5" name="#Cloud #OnenineCloud #NoCode" title="Discover being data driven with OneNine Cloud" desc="Everyone can be data driven. We are on a journey to help students, parents & general workforce to be data driven using power of Data Analysis, Machine Learning, Deep Learning, AI and Cloud Computing. Our platform automates"/>
                        </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                     <div className="blog__wrapper mr-50">
                     <Newsitem to="newsdetails4" date="August 15, 2021" image="6" name="#AI #FutureOfAi #AutoML #NoCode" title="Future State of AI" desc="As Artificial intelligence becomes mainstream, organizations are looking for faster ways to develop & integrate it within their key products. You have probably heard that developing AI requires lots of data observations, so a machine can learn"/>
                        </div>
                  </div>




                       

                      

                        
                     

                 
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogsArea;