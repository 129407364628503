import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails10area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI named key player for Global Artificial Intelligence (AI) in Education Market</h1>
                           <p className='wee'>July 4, 2023</p>
                           <p className='pb-0'>OneNine AI is named as a top players in AI Education market by Information Research Insights. The Global Artificial Intelligence (AI) in Education Market is expected to witness an impressive CAGR of 19.7% during the forecast period of 2020 to 2027, according to the latest market research report from Verified Market Reports.</p>
                           
                           <p className='pb-0'>AI is shaping future of learning and OneNine AI is partnering with educational leaders across the world to bring Data & AI literacy into K-12 curriculum.</p>
                           <p className='pb-0'>Artificial intelligence (AI) has the potential to transform education by providing personalized learning experiences and facilitating better decision-making by educators. However, the implementation of AI in education has been limited by the high cost and complexity of developing and deploying AI applications.</p>
                           <p className='pb-0'>OneNine AI aims to address this challenge by offering a no-code AI technology for education that enables K-12 educators to create custom AI applications without requiring any programming skills.</p>
                        </div>

                        <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail10.jpg" alt="" />
                        </div>


                          
                        <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/details/6.jpg" alt="" />
                        </div>

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails10area;