import React from 'react';
//import { Link } from 'react-router-dom';
import Hero_an from './hero_animation2.json';
import Lottie from 'lottie-react';
//import { HashLink } from 'react-router-hash-link';

const ChatbotHero = (props) => {
   const { scroll } = props
   return (
      <>
         <section className="hero__area p-relative">
            <div className="hero__shape">
               {/* <img className="one" src="assets/img/icon/slider/03/icon-1.png" alt="" /> */}
               <img className="two" src="assets/img/icon/slider/03/icon-2.png" alt="" />
               <img className="three" src="assets/img/icon/slider/03/icon-3.png" alt="" />
               <img className="four" src="assets/img/icon/slider/03/icon-4.png" alt="" />
               <img className="five" src="assets/img/icon/slider/03/icon-6.png" alt="" />
               <img className="six" src="assets/img/icon/slider/03/icon-7.png" alt="" />
            </div>
            <div className="hero__item hero__height d-flex align-items-center">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6 col-lg-5 order-last">
                        <div className="hero__thumb-wrapper ml-10 scene p-relative">
                           
                           {/* <div className="hero__thumb two d-none d-md-block d-lg-none d-xl-block">
                              <img className="layer" data-depth="0.3" src="assets/img/slider/03/slider-2.jpg" alt="" />
                           </div>
                           <div className="hero__thumb three d-none d-sm-block">
                              <img className="layer" data-depth="0.4" src="assets/img/slider/03/slider-3.jpg" alt="" />
                           </div>
                           <div className="hero__thumb four d-none d-md-block d-lg-none d-xl-block">
                              <img className="layer" data-depth="0.5" src="assets/img/slider/03/slider-4.jpg" alt="" />
                           </div> */}
                           <img src='assets/img/chatbotHome.png' width={'120%'} height={'140%'} style={{ borderRadius: '2%',boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.4)' }}></img>
                           {/* <Lottie loop={true} animationData ={Hero_an}/> */}
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-7 d-flex align-items-center">
                        <div className="hero__content">
                           <span className='ai'>OneNine AI</span>
                           <h2>Introducing OneNine AIVA,</h2><h2> your AI companion equipped with the power of GPT.</h2>
                           <h4 className='news mt-20 mb-20'>Experience the future of productivity with our advanced AI Assistant. Seamlessly inquire about your documents, extract data from over 200 sources, including URLs and various file formats like Word, PDF, Excel, and more. Streamline your daily routine with simplified research, summarization, and task automation.</h4>
                         
                           <a href="/book-demo" className="col-sm-2 mr-10">
                              <button className='z-btn-1' style={{width:'172px'}}>Book a Demo</button>
                           </a>
                           <a href="https://chat.onenine.ai" target="_blank" rel="noreferrer" className="col-sm-2">
                              <button className='z-btn3 mr-10 mt-2' style={{width:'160px'}}>Start for Free</button>
                           </a>
                            
                           {/* <HashLink to="/platform#vid"> */}
                           
                           
                           {/* <Link to="/about" className="z-btn ">Book Demo</Link> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ChatbotHero;
