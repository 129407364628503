import React from 'react';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeHeader from '../HomeThree/HomeThreeHeader/HomeThreeHeader';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import Contactform from './Form/form';

const Contactus = () => {
   return (
      <>
         <PageHelmet pageTitle="Contact" />
         <HomeThreeHeader/>
         <Contactform /> 
{/* 
         <Hero/>
         <PlatformFeatures/> */}
         <HomeThreeFooter/>

      </>
   );
};

export default Contactus;