import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './AgentCard.css';

const AgentCard = ({ imageSrc, desc, bold }) => {
  return (
    <article>
      <a href="https://chat.onenine.ai" target="_blank" rel="noopener noreferrer" className="card-link">
        <div className="article-wrapper">
          <figure>
            <img src={imageSrc} alt="" />
          </figure>
          <div className="article-body">
            <h3>{bold}</h3>
            <p>{desc}</p>
          </div>
        </div>
      </a>
    </article>
  );
};

const Reviews = ({title}) => {
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    const agentsData = [
      {
        imageSrc: 'assets/img/research.png',
        bold: 'Online Research Agent',
        desc: 'Online research agent is a web-based AI tool designed to assist users in research tasks by providing information and generating content.',
      },
      {
        imageSrc: 'assets/img/code.png',
        bold: 'Coding Assistance Agent',
        desc: 'Coding Assistance Agent is a tool that assists programmers by providing guidance, suggestions, and solutions to code-related challenges.',
      },
      {
        imageSrc: 'assets/img/legal.png',
        bold: 'Legal Agent',
        desc: 'Legal Agent is an AI tool designed for the legal field, offering support with tasks like legal research, document analysis, and case preparation.',
      },
      {
        imageSrc: 'assets/img/medical.png',
        bold: 'Business Development & Invoice Agent',
        desc: 'Supercharge your business growth and streamline invoicing with our intelligent and efficient Business Development and Invoices Assistant bot.',
      },
      {
        imageSrc: 'assets/img/data.png',
        bold: 'Data Analysis Agent',
        desc: 'Unleash the power of data with this cutting-edge bot, helping you uncover valuable insights and make data-driven decisions with ease.',
      },
      {
        imageSrc: 'assets/img/market.png',
        bold: 'Marketing Assistance Agent',
        desc: 'Empowering you with intelligent insights, campaign management, and personalized recommendations to drive growth and maximize ROI.',
      },
      {
        imageSrc: 'assets/img/answergenie.png',
        bold: 'AnswerGenie Document Agent',
        desc: 'An intelligent bot that effortlessly understands unstructured data and provides accurate answers to your questions.',
      },
      {
        imageSrc: 'assets/img/querybot.png',
        bold: 'QueryBot Agent',
        desc: 'Empowering you to effortlessly express your database queries in English and receive instant, precise answers.',
      },
    ];
  
  return (
    <>
      <section className="blog__area grey-bg-20 pt-115 pb-60" style={{ background: `url(assets/img/bg/bg-new.png)`, backgroundPosition: 'top', backgroundSize: 'cover' }}>
        <div className="container">
          <div className="row">
          <div className="section__title section__title-3 text-center mb-50 wow fadeInUp" data-wow-delay=".2s">
                <h3>{title}</h3>
              </div>
          </div>
        </div>
        <div className="container">
          <Carousel responsive={responsive} infinite={true} autoPlay={true} showDots={true} arrows={false} swipeable={false}>
            {agentsData.map((agent, index) => (
              <AgentCard key={index} {...agent} />
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};

export default Reviews;
