import React from 'react';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeHeader from '../HomeThree/HomeThreeHeader/HomeThreeHeader';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import Newsdetails11area from './Newsdetails11area/Newsdetails11area'

const Newsdetails11 = () => {

    return (
        <>
        <PageHelmet pageTitle="Newsdetails" />
        <HomeThreeHeader/>
        <Newsdetails11area/>
                    
        <HomeThreeFooter/>
        </>
    )
}

export default Newsdetails11;