import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails5area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI partners with NVIDIA accelerate AI development using OneNine's No Code Platform</h1>
                           <p className='wee'>April 20, 2023</p>
                           <p className='pb-0'>OneNine AI today announced it has joined NVIDIA Inception, a program designed to nurture startups revolutionizing industries with technology advancements. The program will also offer OneNine the opportunity to collaborate with industry-leading experts and other AI-driven organizations.</p>
                           <p className='pb-0'>By integrating Nvidia's cutting-edge hardware and software technologies with Onenine.ai's intuitive no-code platform, we're taking AI development and deployment to the next level (100X faster).</p>
                           
                           <p className='pb-0'>Our customers can now enjoy lightning-fast performance, scalability, and flexibility when building and deploying AI models for a variety of use cases, from predictive maintenance to computer vision.</p>
                           <p className='pb-0'>At Onenine.ai, we're committed to making AI accessible to everyone, and our partnership with Nvidia is a big step in that direction.</p>
                           <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail5.jpg" alt="" />
                           </div>
                        </div>

                       

                          
                        

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails5area;