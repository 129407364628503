import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails8area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI Partners with NJIT to Forge the Workforce of the Future with AI Skills</h1>
                           <p className='wee'>February 1, 2023</p>
                           <p className='pb-0'>OneNine AI, a pioneering AI platform, recently participated in the highly anticipated Capstone program organized by the esteemed New Jersey Institute of Technology (NJIT). With an impressive turnout of over 300+ students and other participants, the event served as a testament to the growing interest and demand for AI education. OneNine AI is thrilled to announce its partnership with NJIT, a capstone collaboration that aims to empower individuals with AI skills and cultivate a workforce of the future that is well-equipped to harness the power of artificial intelligence.</p>
                           <p className='pb-0'><b>Driving the AI Revolution:</b> Our goal is to bridge the gap between academic institutions and industry needs in the realm of AI. OneNine is dedicated to nurturing a generation of professionals who possess the knowledge and skills to thrive in an AI-driven world.</p>
                           
                           <p className='pb-0'><b>The Successful Capstone Program:</b> The recent Capstone program organized by NJIT provided a platform for students and participants to showcase their projects and engage in meaningful discussions about the potential of AI. OneNine AI's participation in the event demonstrated its commitment to fostering AI education and nurturing future talent. The remarkable turnout of more than 300+ students and others highlight the enthusiasm and eagerness of individuals to explore the realm of AI.</p>
                           <p className='pb-0'><b>Building an AI-Enabled Workforce:</b> We aim to equip students and professionals with the skills necessary to tackle real-world challenges and drive innovation across various industries.</p>
                           <p className='pb-0'><b>Preparing for the Future:</b> As AI continues to reshape our world, the demand for skilled professionals in this domain will only increase. We aim to play a pivotal role in preparing individuals for the AI-centric future by equipping them with the necessary skills and knowledge to thrive in a rapidly evolving landscape. Graduates and participants will possess a competitive edge, making them sought-after candidates in the job market.</p>
                           <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail8.jpg" alt="" />
                           </div>
                        </div>

                       

                          
                        

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails8area;