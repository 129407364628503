import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Newsbar from '../../News/Newsbar/Newsbar';

const Newsdetails6area = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/5.jpg" alt="" />
                        </div>

                        <div className="blog__text mb-40">
                           <h1 className='pb-5 pt-40'>OneNine AI Shines at SWaMfest, Presenting a Breakthrough No-Code AI Platform  </h1>
                           <p className='wee'>November 1, 2022</p>
                           <p className='pb-0'>OneNine AI recently made waves at the highly acclaimed SWaMfest event. With a captivating presentation, OneNine AI showcased its cutting-edge no-code AI platform, revolutionizing the way individuals and businesses engage with AI technologies. The event served as a platform to highlight the power and accessibility of OneNine AI's innovative platform, reinforcing its commitment to democratizing AI and empowering users to leverage its potential without the need for extensive coding expertise.</p>
                           <p className='pb-0'>Attendees were thrilled to witness the seamless integration of AI capabilities into their workflows, without the usual barriers associated with complex coding requirements.</p>
                           
                           <p className='pb-0'><b>Democratizing AI with No-Code:</b> OneNine AI's no-code AI platform disrupts the traditional approach to AI development, making it accessible to users from diverse backgrounds and skill sets. The platform eliminates the need for extensive coding knowledge, enabling individuals and businesses to leverage the benefits of AI without a steep learning curve. By democratizing AI, OneNine AI opens doors for innovation and empowers users to unleash the potential of AI in their respective domains.</p>
                           <p className='pb-0'><b>The Power of Simplicity:</b> OneNine AI's no-code platform provides an intuitive interface that allows users to build AI models and deploy them quickly and efficiently. Users can easily train models, integrate data sources, and utilize AI-powered features through a user-friendly visual interface. This simplicity enables users to focus on their specific use cases and derive valuable insights without being overwhelmed by technical complexities.</p>
                           <p className='pb-0'><b>Unlocking Possibilities:</b> The no-code AI platform presented by OneNine AI at SWaMfest showcased the vast range of applications and possibilities. From predictive analytics to natural language processing, computer vision to anomaly detection, the platform empowers users to solve complex problems and gain actionable insights. By removing the barriers of coding, OneNine AI's platform encourages creativity and innovation, allowing users to explore the full potential of AI in their unique contexts.</p>
                           <div className="blog__details-thumb w-img mb-35">
                           <img src="assets/img/blog/newsdetail6.jpg" alt="" />
                           </div>
                        </div>

                       

                          
                        

                        

                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.linkedin.com/company/onenine-ai" target="_blank">
                                       <i ><FaLinkedin /></i>
                                       <i ><FaLinkedin /></i>
                                    </a>
                                 </li>
                                 {/* <li>
                                    <a href="https://twitter.com/" target="_blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                       <i ><FaFacebookF /> </i>
                                       <i ><FaFacebookF /> </i>
                                    </a>
                                 </li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <Newsbar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default Newsdetails6area;